import './scss/app.scss';

const jquery = require('jquery');
window.$ = window.jQuery = jquery;

import "bootstrap";

import "slick-carousel";
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

//#region Home Page Modal
$(function () {
    if (document.getElementById("HomePageModal")) {

        document.addEventListener('scroll', function () {

            if (window.scrollY > 300) {
                if (sessionStorage.getItem("pellet1-modal") !== 'true') {
                    sessionStorage.setItem("pellet1-modal", "true");
                    $("#HomePageModal").modal();
                }
            }

        }, { passive: true });

        document.getElementById("modalDismiss").addEventListener("click", CloseModal, { passive: true })
        function CloseModal() {
            $('#HomePageModal').modal('hide');
        }
    }
})
//#endregion

//#region Slider
$(function () {
    $('.slider-home').slick();

    $('.slider').slick({
        arrows: false, dots: false, infinite: true, speed: 500,
        autoplay: true, autoplaySpeed: 3000, slidesToShow: 1, slidesToScroll: 1
    });
    //On click of slider-nav childern,
    //Slick slider navigate to the respective index.
    $('.slider-nav > div').click(function () {
        $('.slider').slick('slickGoTo', $(this).index());
    })
});
//#endregion

//#region event tag manager
$(function () {

    $(document).on("click", ".registerClick", function (e) {
        var $me = $(this);
        RegisterProductClick({
            "name": $me.data("name"),
            "id": $me.data("id"),
            "url": $me.attr("href"),
        });
    });

    var RegisterProductClick = function (productObj) {
        dataLayer.push({
            'event': 'productClick',
            'ecommerce': {
                'click': {
                    'products': [{
                        'name': productObj.name,                      // Name or ID is required.
                        'id': productObj.id
                    }]
                }
            },
            'eventCallback': function () {
                document.location = productObj.url
            }
        });
        return false;
    }
})
//#endregion